import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Navbar from '~/components/Navbar';
import HighlightedTitle from '~/components/HighlightedTitle';
import {
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	Link,
	Stepper,
	Typography,
	useStepperContext,
} from '@mui/material';
import CustomStepper from '~/components/CustomStepper';
import api, { usePut, useQuery, usePost, useGet } from '~/utils/api';
import { bakeCookie, readCookie } from '~/utils/storage';
import { Session, SessionVariables } from '~/models/sessions';
import { SessionContext, useSession } from '~/utils/contexts';
import SwipeableEdgeDrawer from '~/components/BasketDrawer';
import styled from '@emotion/styled';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import DialogPopup from '~/components/DialogPopup';
import { IBasketItem } from '~/models/BasketItem';
import { FormikProps, replace } from 'formik';
import theme from '~/components/Theme';
import SideBar from '~/components/SideBar';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSnackbar } from '~/components/Snackbar';
import KarmadilloSpinner from '~/components/KarmadilloSpinner';
import usePrevious from '~/hooks/usePrevious';
import { diff } from 'deep-diff';
import { forceLoad } from '@sentry/react';

export const BasketContext = React.createContext<{
	basketItems: IBasketItem[];
	setBasketItems: Dispatch<SetStateAction<Session['products'][]>>;
}>({
	basketItems: [],
	setBasketItems: () => null,
});

export const stepOrder: SessionVariables['step'][] = [
	'item-selection',
	// 'add-on',
	'name',
	'address',
	'email-phone',
	// 'verify-phone',
	'disclaimers',
	'charity',
	// 'start-date',
	'checkout',
];

const Layout: React.FC = () => {
	const scrollable = useRef<HTMLDivElement>(null);
	const sessionId = readCookie('sessionId');
	const sessionSecret = readCookie('sessionSecret');
	const [openSnackbar] = useSnackbar();
	const [waiting, setWaiting] = useState(false);
	const [session, setSession] = useState<Session | null>();
	const hasAwinRun = useRef(false);
	const merchantId = 58027; // Always keep at 58027, this is the AK AWIN ID
	const channel = readCookie('utm_source', false) ?? ''; // referring domain
	const commissionGroup = 'DEFAULT'; // Don't change
	const voucherCode = session?.discountCode ?? ''; // only fills out if they have a voucher code accepted
	const currencyCode = 'GBP';
	const awc = readCookie('_awin_awc', false); // from awin1 awclick.php "?awc="
	const policyId = session?.genesys?.policyId; // unique policyId from our database
	const awinUrl = `https://www.awin1.com/sread.php?tt=ss&tv=2&merchant=${merchantId}&amount=1.00&ch=${channel}&parts=${commissionGroup}:1.00&vc=${voucherCode}&cr=${currencyCode}&ref=${policyId}&testmode=0${
		!!awc ? `&cks=${awc}` : ''
	}`;
	const { mutate: sendAwc } = usePost(awinUrl);

	const [awinImage, setAwinImage] = useState();
	useEffect(() => {
		setTimeout(() => {
			let $tidioBubble;
			$tidioBubble = document.querySelector('#tidio-chat');
			if ($tidioBubble) {
				$tidioBubble.classList.add('force-z-index');
			}
		}, 500);
	}, []);

	const [err, setErr] = useState<{
		isError: boolean;
		errorName: string;
		status?: number;
	}>({
		isError: false,
		errorName: '',
		status: 0,
	});

	useEffect(() => {
		if (err.errorName === 'FraudDeclined') {
			navigate('/unavailable');
		}
		// if (err.errorName === 'InvalidDiscountCode') {
		// 	openSnackbar('Indvalid Discount Code', 'error');
		// }
	}, [err]);

	useEffect(() => {
		console.log('policyId', policyId);

		// hasAwinRun was added to forcibly ensure that this request never runs more than once, we have had reports
		// prior that duplicate transactions have come through so this was a workaround to ensure it doesn't.
		if (!!policyId && hasAwinRun.current === false) {
			hasAwinRun.current = true;

			const awin = (window as any).AWIN;

			(window as any).AWIN = {
				...(awin ?? {}),
				Tracking: {
					...(awin?.Tracking ?? {}),
					Sale: {
						...(awin?.Tracking?.Sales ?? {}),
						amount: 1.0,
						channel: channel,
						orderRef: policyId,
						parts: 'DEFAULT:1.00',
						currency: 'GBP',
						voucher: voucherCode,
						test: '0',
					},
				},
			};

			(window as any).awPixel = new Image(0, 0);
			(
				window as any
			).awPixel.src = `https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=58027&amount="1.00"&ch="${channel}"&parts=DEFAULT:"1.00"&ref=”${policyId}"&vc="${voucherCode}"&cr="${currencyCode}"&testmode=0`;

			// Trigger sread.php (server to server) request to AWIN once the genesys policy id exists.
			sendAwc();
		}

		if ((window as any).awPixel) {
			const awPixelSrc = (window as any).awPixel.getAttribute('src');

			setAwinImage(awPixelSrc);
		}
	}, [session]);

	const {
		error,
		data: _querySession,
		refetch: reloadSession,
	} = useQuery<any, Session>(({ post, get }) => {
		if (sessionId) {
			return get(api.sessions.session(sessionId), {
				params: {
					secret: sessionSecret,
				},
				onSuccess: (data) => {
					setSession(data);
				},
				onError: (err) => {
					setErr({
						isError: true,
						errorName: (err as any)?.response?.data?.errorName,
					});
				},
				cacheTime: 0,
			});
		} else {
			return post(api.sessions.sessions, {
				onSuccess: (data) => {
					bakeCookie('sessionId', data._id);
					bakeCookie('sessionSecret', data.secret);
					setSession(data);
				},
				onError: (err) => {
					setErr({
						isError: true,
						errorName: (err as any)?.response?.data?.errorName,
					});
				},
			});
		}
	});

	const {
		error: sessionError,
		data: _postSession,
		mutateAsync: _updateSession,
	} = usePut<Session, SessionVariables>(api.sessions.session(sessionId), {
		onSuccess: (data) => {
			setSession(data);
		},
		onError: (err) => {
			setErr({
				isError: true,
				errorName: (err as any)?.response?.data?.errorName,
			});
		},
	});

	const updateSession = async (variables: SessionVariables) => {
		if (!session?._id) return;
		try {
			return await _updateSession({
				...variables,
				seonSession: $seonBase64Session.current,
			});
		} catch (err) {
			// openSnackbar((err as any).response.data.errorMessage, 'error');
			setErr({
				isError: true,
				errorName: (err as any)?.response?.data?.errorName,
			});
		}
	};

	const { data: _finalizedSession, mutateAsync: finalizeSession } = usePost<
		Session,
		{ force?: boolean }
	>(api.sessions.finalize(sessionId), {
		onSuccess: (data) => {
			setSession(data);
		},
		onError: (err) => {
			if ((err as any)?.response?.data?.errorName === 'FraudDeclined') {
				navigate('/available');
			}
		},
		requestConfig: {
			timeout: 0,
		},
	});

	const { data: _syncedSession, mutateAsync: syncSession } = usePost<Session>(
		api.sessions.sync(sessionId),
		{
			onSuccess: (data) => {
				setSession(data);
			},
		}
	);
	const { data: _startSession, mutateAsync: startVerifyPhone } = usePost<Session>(
		api.sessions.phoneVerify(sessionId)
	);
	const { data: _confirmSession, mutateAsync: confirmVerifyPhone } = usePost<
		Session,
		{ code: string }
	>(api.sessions.phoneConfirm(sessionId));

	const location = useLocation();
	const navigate = useNavigate();

	const {
		data: totalPrice,
		refetch,
		isFetching,
	} = useGet<{ quote: number }>(api.sessions.quote(session?._id), {
		requestConfig: {
			timeout: 0,
		},
		enabled: !!session?._id,
	});

	const prevProducts = usePrevious(session?.products);
	const prevAddOns = usePrevious(session?.addOns);
	const prevDiscountCode = usePrevious(session?.discountCode);
	useEffect(() => {
		if (
			!diff(prevProducts, session?.products) &&
			!diff(prevAddOns, session?.addOns) &&
			!diff(prevDiscountCode, session?.discountCode)
		)
			return;

		void refetch();
	}, [session?.products, session?.addOns, session?.discountCode]);

	const [dialogOpen, setDialogOpen] = useState<boolean>(false);

	const [openDrawer, setOpenDrawer] = React.useState(false);

	const [basketItems, setBasketItems] = useState<IBasketItem[]>([
		// {
		// 	name: 'Airpods 2019',
		// 	category: 'earphones',
		// 	price: 8.99,
		// 	icon: '',
		// 	code: 'airpods-2019',
		// },
	]);

	const [allowProgress, setAllowProgress] = useState<boolean>(true);

	const [stepIndex, setStepIndex] = useState<number>(1);

	const currentFormRef = useRef<FormikProps<any>>(null);
	const handleSubmitRef = useRef<(() => Promise<boolean> | boolean) | null>(null);

	const isInitialFlow = useRef(true);

	useEffect(() => {
		if (!isInitialFlow.current) return;
		if (!session?.step) return;
		isInitialFlow.current = false;

		const page = location.pathname.split('/').pop();
		let stepIndex;
		if (page === 'manual-entry') {
			stepIndex = stepOrder.indexOf('address');
		} else {
			stepIndex = stepOrder.indexOf(page as any);
		}
		const sessionStepIndex = stepOrder.indexOf(session.step);

		// Don't run on invalid routes
		if (stepIndex === -1) return;

		if (stepIndex > sessionStepIndex) {
			setStepIndex(sessionStepIndex);
			navigate(`/${session.step}`);
		} else {
			setStepIndex(stepIndex);
		}
	}, [location.pathname, session?.step]);

	useEffect(() => {
		const page = location.pathname.split('/').pop();

		if (page === 'manual-entry') {
			setStepIndex(stepOrder.indexOf('address'));
		} else {
			setStepIndex(stepOrder.indexOf(page as any));
		}
	}, [location.pathname]);

	useEffect(() => {
		if (
			session?.genesys?.status === 'active' &&
			location.pathname.split('/').pop() !== 'thank-you'
		) {
			navigate('/thank-you');
		}
	}, [session?.genesys?.status]);

	const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');

	// Forcibly remove any old handlers
	useEffect(() => {
		handleSubmitRef.current = null;
	}, [location.pathname]);

	const previousBasketLength = usePrevious(session?.products?.length) ?? 0;

	useEffect(() => {
		if (session?.products?.length === 0 && previousBasketLength > 0) {
			navigate('/item-selection');
			openSnackbar('You must have at least one item to insure', 'warning');
		}
	}, [session?.products]);

	const $seonBase64Session = useRef<string | null>(null);
	useEffect(() => {
		if ((window as any).seon) {
			// Notify seon of session ID
			(window as any).seon.config({
				session_id: session?._id,
			});

			(window as any).seon.getBase64Session(async (data: string) => {
				// This is a payload that the API will forward to SEON.
				$seonBase64Session.current = data;
				await updateSession({});
			});
		}
	}, [session?._id]);

	function sleep(ms: number): Promise<void> {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	return (
		<Styles>
			{/*trustpilot script*/}
			<script
				type="text/javascript"
				src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
				async
			></script>

			<SessionContext.Provider
				value={{
					session,
					step: session?.step,
					stepIndex: stepIndex,
					updateSession: async (values: SessionVariables) =>
						await updateSession({ ...values }),
					setStep: async (step) => await updateSession({ step }),
					setCharity: async (charityId: string, pickedForMe?: boolean) =>
						await updateSession({
							charity: {
								chosenCharity: charityId,
								pickedForMe: pickedForMe || false,
							},
						}),
					addItemToSession: async (newItem) => {
						await updateSession({
							products: [...(session?.products ?? []), ...(newItem ?? [])],
						});
					},
					addAddOnToSession: async (addOn) => {
						return await updateSession({
							addOns: [...(session?.addOns ?? []), ...(addOn ?? [])],
						});
					},
					editItemInSession: async (item, newValue) => {
						const sessionProducts = session?.products ?? [];
						const replaceIndex = sessionProducts.findIndex((product) => {
							return product === item;
						});

						if (replaceIndex !== -1) {
							sessionProducts[replaceIndex] = newValue;
						}

						return await updateSession({
							products: sessionProducts,
						});
					},
					removeAddOnFromSession: async (addOn) =>
						await updateSession({
							addOns: (session?.addOns ?? []).filter(
								(currentAddOn) => currentAddOn !== addOn
							),
						}),
					removeItemFromSession: async (product) =>
						await updateSession({
							products: session?.products
								?.filter((currentProduct) => currentProduct !== product)
								?.map((product) => ({
									...product,
								})),
						}),
					finalizeSession: async (force = false) => {
						const sess = await finalizeSession({ force });
						return sess;
					},
					syncSession: async () => {
						const sess = await syncSession();
						return sess;
					},
					allowProgress: allowProgress,
					setAllowProgress: setAllowProgress,
					startVerifyPhone: async () => {
						const sess = await startVerifyPhone();
						await reloadSession();
						return sess;
					},
					confirmVerifyPhone: async (data) => {
						const sess = await confirmVerifyPhone(data);
						await reloadSession();
						return sess;
					},
					currentFormRef,
					handleSubmitRef,
					totalPrice,
					isFetchingTotalPrice: isFetching,
					error: err,
				}}
			>
				<Box className="layout-wrapper">
					{awinImage && (
						<img
							style={{ position: 'absolute' }}
							loading="lazy"
							src={awinImage} // Use the image variable here
							alt={'Image Alt Text'}
							width={60}
							height={50}
						/>
					)}

					<Navbar />
					<Container className="master-container">
						<Grid
							container
							className="layout-wrapper-grid"
							justifyContent="space-between"
							alignItems="center"
						>
							<Grid item xs={12} md={8} lg={7} className="wrapper">
								<Container className="wrapper-container">
									<Box ref={scrollable} className="scrollable-content">
										<CustomStepper location={location} />
										<HighlightedTitle
											location={location}
											title="select your items"
											subtitle="Pick the items that you'd like to protect"
											underlineLastWord
										/>
										{['item-selection'].includes(
											location?.pathname?.split('/').pop()
										) && (
											<Button
												variant="contained"
												className="next-step-button mobile top"
												startIcon={
													waiting ? <KarmadilloSpinner size={20} /> : null
												}
												onClick={async () => {
													setWaiting(true);
													// new handlesubmit function, overrides any currentForm work
													if (
														handleSubmitRef.current &&
														!(await handleSubmitRef.current())
													) {
														setWaiting(false);
														return;
													} else if (currentFormRef.current) {
														currentFormRef.current.handleSubmit();
														const errors =
															await currentFormRef.current.validateForm();

														if (Object.keys(errors).length !== 0) {
															setWaiting(false);
															return;
														}
													}

													await updateSession({
														step: stepOrder[stepIndex + 1],
													});

													if (err.errorName === 'FraudDeclined') return;
													navigate(`/${stepOrder[stepIndex + 1]}`);
													setWaiting(false);
												}}
												disabled={!allowProgress || waiting}
											>
												next
											</Button>
										)}

										<Elements stripe={stripePromise}>
											<Outlet context={scrollable} />
										</Elements>

										{!['checkout', 'thank-you'].includes(
											location?.pathname?.split('/').pop()
										) && (
											<Button
												variant="contained"
												className="next-step-button mobile"
												startIcon={
													waiting ? <KarmadilloSpinner size={20} /> : null
												}
												onClick={async () => {
													setWaiting(true);
													// new handlesubmit function, overrides any currentForm work
													if (
														handleSubmitRef.current &&
														!(await handleSubmitRef.current())
													) {
														setWaiting(false);
														return;
													} else if (currentFormRef.current) {
														currentFormRef.current.handleSubmit();
														const errors =
															await currentFormRef.current.validateForm();

														if (Object.keys(errors).length !== 0) {
															setWaiting(false);
															return;
														}
													}

													await updateSession({
														step: stepOrder[stepIndex + 1],
													});

													navigate(`/${stepOrder[stepIndex + 1]}`);
													setWaiting(false);
												}}
												disabled={!allowProgress || waiting}
											>
												next
											</Button>
										)}
									</Box>
								</Container>
							</Grid>

							<Grid item xs={0} md={4} className="sidebar-wrapper">
								<SideBar />
							</Grid>
						</Grid>
					</Container>
					<Box className="next-button-bar">
						{!['checkout', 'thank-you'].includes(
							location?.pathname?.split('/').pop()
						) && (
							<Button
								variant="contained"
								className="next-step-button desktop"
								startIcon={
									waiting ? <KarmadilloSpinner color="green" size={20} /> : null
								}
								onClick={async () => {
									setWaiting(true);
									// new handlesubmit function, overrides any currentForm work

									if (
										handleSubmitRef.current &&
										!(await handleSubmitRef.current())
									) {
										setWaiting(false);
										return;
									} else if (currentFormRef.current) {
										currentFormRef.current.handleSubmit();
										const errors = await currentFormRef.current.validateForm();

										if (Object.keys(errors).length !== 0) {
											setWaiting(false);
											return;
										}
									}

									setStepIndex((value) => value + 1);

									await updateSession({
										step: stepOrder[stepIndex + 1],
									});

									if (session?.fraudAnalysis?.result === 'DECLINE') {
										navigate('/unavailable');
									} else {
										navigate(`/${stepOrder[stepIndex + 1]}`);
										setWaiting(false);
									}
								}}
								disabled={!allowProgress || waiting}
							>
								next
								<KeyboardDoubleArrowRightIcon className="next-step-button-icon" />
							</Button>
						)}
					</Box>
					{/*<Box className="karmadillo">*/}
					{/*	<img*/}
					{/*		src="https://arma-karma.s3.eu-west-2.amazonaws.com/assets/greenLogo.svg"*/}
					{/*		className="karmadillo-img"*/}
					{/*		alt="karmadillo"*/}
					{/*	/>*/}
					{/*</Box>*/}
					<Box className="drawer-container">
						<Box
							className={'drawer-click-handler'}
							sx={{ pointerEvents: !openDrawer ? 'all' : 'none' }}
							onClick={() => {
								if (!openDrawer) {
									setOpenDrawer(true);
								}
							}}
						></Box>
						{stepIndex <= stepOrder.findIndex((step) => step === 'checkout') && (
							<SwipeableEdgeDrawer open={openDrawer} setOpen={setOpenDrawer} />
						)}
					</Box>
				</Box>
			</SessionContext.Provider>
		</Styles>
	);
};
const Styles = styled.div`
	background-color: ${({ theme }) => theme.palette.primary.light};
	z-index: 1;
	position: relative;
	${({ theme }) => theme.breakpoints.up('sm')} {
		padding-bottom: ${({ theme }) => theme.spacing(8)};
	}

	.drawer-click-handler {
		position: absolute;
		right: 0;
		bottom: 0;
		background: transparent;
		height: 100px;
		width: 50%;
		z-index: 1200;
	}

	.master-container {
		max-width: 1400px;
		padding: 0;
	}

	.next-button-bar {
		z-index: 2;
		height: auto;
		background-color: white;
		position: fixed;
		bottom: 0;
		width: 100vw;
		display: flex;
		justify-content: center;

		${({ theme }) => theme.breakpoints.down('md')} {
			display: none;
		}

		* {
			max-width: 400px;
		}
	}

	.wrapper {
		padding-right: 0;
		${({ theme }) => theme.breakpoints.up('md')} {
			padding-right: ${({ theme }) => theme.spacing(4)};
		}
	}

	.layout-wrapper {
		position: relative;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;

		${({ theme }) => theme.breakpoints.up('md')} {
			max-height: unset;
		}

		&-grid {
			height: calc(100vh - 100px);
			align-items: flex-start;

			${({ theme }) => theme.breakpoints.up('md')} {
				height: auto;
				padding: ${({ theme }) => theme.spacing(0, 4)};
			}
		}

		&-container {
			max-width: 1400px;
		}
	}

	.drawer-container {
		${({ theme }) => theme.breakpoints.up('md')} {
			display: none;
		}
	}

	.outlet-grid-item {
		${({ theme }) => theme.breakpoints.up('md')} {
			padding-right: 2rem;
		}
	}

	.sidebar-wrapper {
		align-self: flex-start;
		margin-top: ${({ theme }) => theme.spacing(16)};
		padding: ${({ theme }) => theme.spacing(3)};
		min-height: 600px;
		border-radius: 12px;
		background-color: white;
		box-shadow: 0px 0px 14px rgba(123, 123, 123, 0.42);

		display: flex;
		flex-direction: column;

		${({ theme }) => theme.breakpoints.down('md')} {
			display: none;
		}
	}
	.karmadillo {
		max-height: 125px;
		overflow: hidden;
		z-index: -1;
		position: absolute;
		top: 75vh;
		width: 100%;
		display: grid;
		place-items: center;

		${({ theme }) => theme.breakpoints.up('md')} {
			display: none;
		}
		&-img {
			object-fit: contain;
			opacity: 0.1;
			width: 80%;
		}
	}

	.wrapper {
		height: calc(100%);
		&-container {
			height: 100%;
			position: relative;
			flex: 1;
			background-color: transparent;
			padding: 0;
		}
	}

	.scrollable-content {
		overflow-x: visible;
		overflow-y: hidden;
		max-height: 100%;
		${({ theme }) => theme.breakpoints.up('sm')} {
			max-height: 100%;
		}
		padding: ${({ theme }) => theme.spacing(2)};
		padding-bottom: ${({ theme }) => theme.spacing(12)};

		${({ theme }) => theme.breakpoints.down('md')} {
			padding-bottom: ${({ theme }) => theme.spacing(20)};
			padding-right: ${({ theme }) => theme.spacing(4)};
			padding-left: ${({ theme }) => theme.spacing(4)};
			overflow-y: scroll;
		}

		${({ theme }) => theme.breakpoints.only('xs')} {
			padding: ${({ theme }) => theme.spacing(2, 2, 20, 2)};
		}
	}

	.next-step-button.top {
		${({ theme }) => theme.breakpoints.down('md')} {
			margin-top: 0;
		}
	}
	.next-step-button {
		width: 100%;
		margin: ${({ theme }) => theme.spacing(3, 0)};
		display: flex;
		align-items: center;

		&.desktop {
			${({ theme }) => theme.breakpoints.down('md')} {
				//display: none;
			}
		}

		&.mobile {
			${({ theme }) => theme.breakpoints.up('md')} {
				display: none;
			}
		}

		&-icon {
			font-size: 32px;
		}
	}

	.dialog-link,
	.dialog-title {
		color: ${({ theme }) => theme.palette.primary.dark};
	}

	.dialog-body {
		padding: ${({ theme }) => theme.spacing(2, 0)};
	}
`;

export default Layout;
